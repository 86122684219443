<template>
    <div>
        <v-card>
            <v-container>
                <v-row style="padding: 10px 25px;">
                    <span class="subheading font-weight-bold" style="font-size: 20px">时间：</span><span
                        style="margin-top: 5px">{{record.submissionTime.substring(0,10)}}</span>
                </v-row>
                <v-row style="padding: 10px 25px;">
                    <span class="subheading font-weight-bold">房屋安全等级：</span><span>{{record.houseRank}}</span>
                </v-row>
            </v-container>
            <v-form>
                <v-container fluid>
                    <template>
                        <v-row v-show="record.indexes===0">
                            <v-col cols="4" v-for="i in 3">
                                <v-card>
                                    <v-sheet class="pa-3">
                                        <v-skeleton-loader class="mx-auto" max-width="320"
                                                           type="article, actions">
                                        </v-skeleton-loader>
                                    </v-sheet>
                                </v-card>
                            </v-col>
                        </v-row>
                        <template v-show="record.indexes.length!==0">
                            <v-row>
                                <v-col v-for="(chtemp,ci) in record.indexes" :key="ci"
                                       cols="16" sm="6" md="4" lg="4" style="margin-bottom: 10px">
                                    <v-card>
                                        <v-card-title class="subheading font-weight-bold">
                                            <v-row>
                                                {{chtemp.title}}
                                            </v-row>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <v-list dense>
                                            <v-container fluid style="padding: 0px 10%">
                                                <v-row v-for="(ptemp,pi) in chtemp.infos">
                                                    <v-row align="center"
                                                           style="margin-top:0px;padding: 0px;margin-bottom: 10px">
                                                        <v-col style="margin-top: 10px" cols="6">
                                                            <span class="head" style="margin-top: 0px">
                                                                {{ptemp.name}}:
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="5" class="d-flex-file"
                                                               style="margin-top:10px;padding: 0px">
                                                            <v-chip>
                                                                {{ptemp.rank}}
                                                            </v-chip>
                                                        </v-col>
                                                    </v-row>
                                                    <v-col cols="6"
                                                           style="margin-top:20px;padding: 0px;margin-left:2%;margin-bottom: 10px;color: #bfbfbf">
                                                        {{ptemp.des}}
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-list>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6" md="4"
                                       lg="4" style="margin-bottom: 10px">
                                    <v-card>
                                        <v-card-title class="subheading font-weight-bold">
                                            其他
                                        </v-card-title>
                                        <v-container>
                                            <v-textarea readonly name="input-7-4" label="请输入描述"
                                                        v-model="this.record.other.des"
                                            ></v-textarea>
                                            <v-file-input prepend-icon="mdi-camera" multiple hide-input
                                            ></v-file-input>
                                        </v-container>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="4" style="margin-bottom: 10px"
                                >
                                    <v-card>
                                        <v-card-title class="subheading font-weight-bold">
                                            房屋安全等级
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <v-list dense>
                                            <v-container class="px-0-radio" fluid style="margin-left: 20px">
                                                <v-radio-group v-model="record.houseRank" mandatory>
                                                    <v-radio v-for="(radio,i) in houseRanks" :key="i"
                                                             :label="radio + houseRanksItems[i]"
                                                             :value="radio">
                                                    </v-radio>
                                                </v-radio-group>
                                            </v-container>
                                        </v-list>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>
                    </template>
                </v-container>
            </v-form>
        </v-card>
    </div>
</template>

<script>
    import {record} from "@/network/Details/general_page/dailyHistory.js";

    export default {
        created() {
            record({
                recordId: this.$route.query.recordId,
                roomId: this.$route.query.roomId
            }).then(res => {
                if (res.status === 200) {
                    this.record = res.data
                }
            }).catch(err => {
                console.log(err);
            })
            // history({
            //     roomId: 1
            // }).then(res => {
            //     this.historyIndexes = res.data
            // }).catch(err => {
            //     console.log(err);
            // })
            getRanks({}).then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    this.ranksGrade.push(res.data[i].infoRankName)
                    this.form.push({
                        des: '',
                        rank: ''
                    })
                }
                this.ranks = res.data
            }).catch(err => {
                console.log(err);
            })
        },
        methods: {
            historyButton() {
                this.historyDialogVisible = true
            },
            rankOnClick(ranks) {
                console.log(ranks)
            },
        },
        data: () => ({
            historyDialogVisible: false,
            form: [],
            dailyText: '',
            historyIndexes: '',
            record: {
                recordId: 1,
                indexes: [
                    {
                        indexId: 1,
                        title: "电器类",
                        infos: [
                            {
                                infoId: 1,
                                indexId: 5,
                                name: "插座",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 2,
                                indexId: 6,
                                name: "线路",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 3,
                                indexId: 7,
                                name: "照明",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 4,
                                indexId: 8,
                                name: "开关",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            }
                        ]
                    },
                    {
                        indexId: 2,
                        title: "结构类",
                        infos: [
                            {
                                infoId: 5,
                                indexId: 9,
                                name: "地面",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 6,
                                indexId: 10,
                                name: "墙面",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 7,
                                indexId: 11,
                                name: "吊顶",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 8,
                                indexId: 12,
                                name: "排水",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            }
                        ]
                    },
                    {
                        indexId: 3,
                        title: "大型电器类",
                        infos: [
                            {
                                infoId: 9,
                                indexId: 13,
                                name: "空调",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 10,
                                indexId: 14,
                                name: "热水器",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 11,
                                indexId: 15,
                                name: "冰箱",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 12,
                                indexId: 16,
                                name: "电视机",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            }
                        ]
                    },
                    {
                        indexId: 4,
                        title: "门窗类",
                        infos: [
                            {
                                infoId: 13,
                                indexId: 17,
                                name: "入户门",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 14,
                                indexId: 18,
                                name: "卧室门",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 15,
                                indexId: 19,
                                name: "窗户",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 16,
                                indexId: 20,
                                name: "门锁",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            }
                        ]
                    }
                ],
                houseRank: "A",
                other: {
                    des: "其它故障描述...",
                    imgUrl: ""
                }
            },
            ranks: '',
            ranksGrade: [],
            houseRanks: ['A', 'B', 'C', 'D'],
            houseRanksItems: [
                ':结构承载力能满足正常使用要求，未腐朽危险点，房屋结构安全。',
                '：结构承载力基本满足正常使用要求，个别结构构件处于危险状态，但不影响主体结构，基本满足正常使用要求。',
                '：部分承重结构承载力不能满足正常使用要求，局部出现险情，构成局部危房。',
                ':承重结构承载力已不能满足正常使用要求，房屋整体出现险情，构成整幢危房。'
            ]
        }),
        name: "DailyHistory"
    }
</script>

<style scoped>

</style>
